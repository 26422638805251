import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Link,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './footer.module.scss';
import { useDispatch } from 'react-redux';
import { newsletterSubscribeHandler } from '../../redux/homeSlice';
import {
  FaCcMastercard,
  FaCcVisa,
  FaFacebook,
  FaGooglePay,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import airtelMoney from '../../assets/img/icons/airtel-money.png';
import eziPay from '../../assets/img/icons/ezipay-logo.svg';
import mPesa from '../../assets/img/icons/mpesa.jpg';
import payPal from '../../assets/img/icons/payPal.svg';
import tigoPesa from '../../assets/img/icons/TigoPesa.png';

const Footer = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {/* <Flex bg='#ddd' justify={'center'} padding={'20px'}>
        <Flex gap={'15px'} align={'center'}>
          <Text mb='0' fontSize={'14px'}>
            Our team regularly selects 7 best deals and discounts - sign up for
            our newsletter and learn about them first!
          </Text>
          <Flex gap={'10px'}>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder='Enter your email address'
            />
            <Button
              isDisabled={!email}
              onClick={() => {
                const regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
                if (!regex.test(email)) {
                  return toast({
                    title: 'Invalid email address',
                    status: 'error',
                  });
                }
                setIsLoading(true);
                dispatch(newsletterSubscribeHandler({ email })).then((res) => {
                  if (res.status === 200) {
                    setEmail('');
                    toast({
                      title: 'Subscribed successfully',
                      status: 'success',
                    });
                    setIsLoading(false);
                  } else {
                    toast({
                      title: 'Subscription failed',
                      status: 'error',
                    });
                    setIsLoading(false);
                  }
                });
              }}
              variant='primary'
              isLoading={isLoading}
              loadingText='Subscribing'
            >
              Subscribe
            </Button>
          </Flex>
        </Flex>
      </Flex> */}

      <Box className={styles.footer} bg='#EEE' p={'40px 120px 20px 120px'}>
        <Flex
          // gap={'130px'}
          className={styles.flex}
          justifyContent='space-between'
        >
          {/* Customer Services  */}
          <Box>
            <Text
              fontSize={'15px'}
              borderBottom={'2px solid #303030'}
              fontWeight={600}
              lineHeight={'20px'}
              paddingBottom={'6px'}
              className={styles.title}
            >
              Customer Services
            </Text>
            <UnorderedList mt='10px' fontSize='13px'>
              <Link as={RouterLink} to='/about-us'>
                <ListItem mb='10px'>About Us</ListItem>
              </Link>
              <Link as={RouterLink} to='/terms-and-conditions'>
                <ListItem mb='10px'>Terms & Conditions</ListItem>
              </Link>
              <Link as={RouterLink} to='/qna'>
                <ListItem mb='10px'>Support center</ListItem>
              </Link>
              <Link as={RouterLink} to='/privacy-policy'>
                <ListItem mb='10px'>Privacy Policy</ListItem>
              </Link>
              <Link as={RouterLink} to='/cookie-notice'>
                <ListItem mb='10px'>Cookie Notice</ListItem>
              </Link>
            </UnorderedList>
          </Box>
          {/* Help & Support  */}
          <Box>
            <Text
              fontSize={'15px'}
              borderBottom={'2px solid #303030'}
              fontWeight={600}
              lineHeight={'20px'}
              paddingBottom={'6px'}
              className={styles.title}
            >
              Help & Support
            </Text>
            <UnorderedList mt='10px' fontSize='13px'>
              {/* <Link as={RouterLink} to='/how-to-order'>
                <ListItem mb='10px'>How to Order</ListItem>
              </Link> */}
              <Link as={RouterLink} to='/how-to-track-order'>
                <ListItem mb='10px'>How to Track </ListItem>
              </Link>
              <Link as={RouterLink} to='/size-guide'>
                <ListItem mb='10px'>Size Guide</ListItem>
              </Link>
              <Link as={RouterLink} to='/refund-policy'>
                <ListItem mb='10px'>Returns and Refunds</ListItem>
              </Link>
              {/* <Link as={RouterLink} to='/contact-us'>
                <ListItem mb='10px'>Contact Us</ListItem>
              </Link> */}
              <Link as={RouterLink} to='/shipping-return-cancellation-policy'>
                <ListItem mb='10px'>Shipment Policy</ListItem>
              </Link>
            </UnorderedList>
          </Box>
          {/* Pay With  */}
          <Box>
            <Text
              fontSize={'15px'}
              borderBottom={'2px solid #303030'}
              fontWeight={600}
              lineHeight={'20px'}
              paddingBottom={'6px'}
              className={styles.title}
            >
              Pay With
            </Text>
            <Flex mt="10px" flexDir="row" gap="20px" fontSize="40px" wrap="wrap">
              <Box width="calc(25% - 15px)">
                <Image src={tigoPesa} alt="mPesa" w="50px" h="35px" />
              </Box>
              <Box width="calc(25% - 15px)">
                <Image src={mPesa} alt="mPesa" w="50px" h="35px" />
              </Box>
              <Box width="calc(25% - 15px)">
                <Image src={airtelMoney} alt="Airtel Money" w="50px" h="35px" />
              </Box>
              <Box width="calc(25% - 15px)">
                <Image src={eziPay} alt="mPesa" w="50px" h="35px" />
              </Box>
              <Box width="calc(25% - 15px)">
                <Image src={payPal} alt="mPesa" w="50px" h="35px" />
              </Box>
            </Flex>

          </Box>
          {/* Contact Us  */}
          <Box>
            <Text
              fontSize={'15px'}
              borderBottom={'2px solid #303030'}
              width='80%'
              fontWeight={600}
              lineHeight={'20px'}
              paddingBottom={'6px'}
              className={styles.title}
            >
              Stay Connected
            </Text>

            <Flex mt='10px' flexDir='row' gap='20px' fontSize='25px'>
              <a
                href='https://www.facebook.com/kwelibuy'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaFacebook style={{ cursor: 'pointer' }} />
              </a>
              <a
                href='https://www.tiktok.com/@kwelibuy'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaTiktok style={{ cursor: 'pointer' }} />
              </a>
              <a
                href='https://www.instagram.com/kweli_buy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaInstagram />
              </a>
              <a
                href='https://x.com/kweliBuy'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaXTwitter style={{ cursor: 'pointer' }} />
              </a>
              <a
                href='https://www.youtube.com/@kwelibuy'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaYoutube style={{ cursor: 'pointer' }} />
              </a>
            </Flex>

            <Box mt='53px' className={styles.downloads}>
              <Text fontSize={'20px'} fontWeight={700}>
                Download App
              </Text>
              <Flex gap={'13px'} mt='28px'>
                <Image
                  height={'32px'}
                  src={require('../../assets/img/app/appstore.png')}
                  className={styles.app}
                />
                <Image
                  height={'32px'}
                  src={require('../../assets/img/app/playstore.png')}
                  className={styles.app}
                />
              </Flex>
            </Box>
          </Box>
        </Flex>

        <Flex justify={'center'} borderTop={'1px solid #ED8B85'} mt='53px'>
          <Text fontSize={'15px'} pt={'15px'}>
            © 2022 All rights reserved.
          </Text>
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
