import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Flex, Box, Text, Divider, IconButton } from '@chakra-ui/react';
import OrderIcon from '../../components/icons/order-icon';
import ArrowIcon from '../../components/icons/arrowIcon';
import WishlistIcon from '../../components/icons/wish-icon';
import ProfileIcon from '../../components/icons/profile-icon';
import RecentIcon from '../../components/icons/recent-icon';
import NotificationIcon from '../../components/icons/notificationIcon';
import HelpIcon from '../../components/icons/helpIcon';
import HeartIcon from '../../components/icons/heart-icon';
import HomeIcon from '../../components/icons/home-icon'; // Assuming you have Home icon
import CartIcon from '../../components/icons/cart-icon'; // Assuming you have Cart icon
import LogoutIcon from '../../components/icons/exit-icon'; // Add logout icon
import { v4 as uuidv4 } from 'uuid';
import { setGuestInfo } from '../../redux/guestSlice';
import AccountHeader from '../../components/header/account-header';
import { setOnLoginOpen } from '../../redux/homeSlice';

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('token') ? true : false;
  const [redirectPath, setRedirectPath] = useState(null);

  const items = [
    {
      name: 'My Details',
      icon: <ProfileIcon />,
      // path: '/profile',
      action: () => isLoggedIn ? navigate('/profile') : dispatch(setOnLoginOpen(true)),
      restricted: true,
    },
    {
      name: 'Recently Viewed',
      icon: <RecentIcon />,
      path: '/recently-viewed',
      disabled: true,
    },
    {
      name: 'Notifications',
      icon: <NotificationIcon />,
    },
  ];

  useEffect(() => {
    if (isLoggedIn && redirectPath) {
      navigate(redirectPath); 
      setRedirectPath(null); 
    }
  }, [isLoggedIn, redirectPath, navigate]);

  // Add a logout option
  const handleLogout = () => {
    // localStorage.clear();
    // localStorage.setItem('isAuthenticated', 'false');
    // const guestId = uuidv4();
    // localStorage.setItem('guestInfo', JSON.stringify({ id: guestId }));
    // dispatch(setGuestInfo(guestId));
    // window.location.href = '/';

    console.log('Logged out');
  };

  const handleMyOrdersClick = () => {
    handleRestrictedOption('/my-orders');
  };

  const handleRestrictedOption = (path) => {
    if (!isLoggedIn) {
      setRedirectPath(path);
      dispatch(setOnLoginOpen(true));
    } else {
      navigate(path);
    }
  };
  
  return (
    <Flex
      direction="column"
      bg='#F0F0F0'
      height={'100%h'}
      justifyContent="space-between"
      // padding={paddingValue}
      padding={0}
    >
      {/* Top section */}
      <Flex
        direction="column"
        gap={'8px'}
      >
        <AccountHeader />
        <Flex
          padding={'20px 30px'}
          bg='#fff'
          justify={'space-between'}
          align={'center'}
          cursor={'pointer'}
          onClick={handleMyOrdersClick}         
        >
          <Flex gap={'16px'} align={'center'}>
            <OrderIcon />
            <Text fontSize={'16px'} fontWeight={600}>
              My Orders
            </Text>
          </Flex>
          <Box>
            <ArrowIcon />
          </Box>
        </Flex>

        <Flex
          padding={'20px 30px'}
          bg='#fff'
          justify={'space-between'}
          align={'center'}
          cursor={'pointer'}
          onClick={() => navigate('/wishlist')}
        >
          <Flex gap={'16px'} align={'center'}>
            <WishlistIcon />
            <Text fontSize={'16px'} fontWeight={600}>
              My Wishlist
            </Text>
          </Flex>
          <Box>
            <ArrowIcon />
          </Box>
        </Flex>

        {/* Other items */}

        {/* Grouped container for My Details, Recently Viewed, and Notifications */}
        <Box bg="#fff">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <Flex
                padding="20px 30px"
                bg="#fff"
                justify="space-between"
                align="center"
                cursor={item.disabled ? 'not-allowed' : 'pointer'}
                opacity={item.disabled ? 0.5 : 1}
                onClick={() => !item.disabled && handleRestrictedOption(item.path)}
              >
                <Flex gap="16px" align="center">
                  {item.icon}
                  <Text fontSize="16px" fontWeight={600}>{item.name}</Text>
                </Flex>
                <Box><ArrowIcon /></Box>
              </Flex>
              {index < items.length - 1 && <Divider width="84%" mx="auto" backgroundColor="#E6E6E6" />}
            </React.Fragment>
          ))}
        </Box>

        {/* Remaining items */}
        <Flex
          padding={'20px 30px'}
          bg='#fff'
          justify={'space-between'}
          align={'center'}
          cursor={'pointer'}
          onClick={() => navigate('/qna')}
          mb={'10px'}
        >
          <Flex gap={'16px'} align={'center'}>
            <HelpIcon />
            <Text fontSize={'16px'} fontWeight={600}>
              Support Center
            </Text>
          </Flex>
          <Box>
            <ArrowIcon />
          </Box>
        </Flex>
        {/* Logout button */}

      </Flex>

      {isLoggedIn && (
        <Flex
          padding={'20px 25px'}
          bg='#fff'
          align={'center'}
          cursor={'pointer'}
          onClick={handleLogout}
        >
          <Box
            onClick={() => {
              localStorage.clear();
              localStorage.setItem('isAuthenticated', 'true');
              let guestId = uuidv4();
              localStorage.setItem(
                'guestInfo',
                JSON.stringify({ id: guestId })
              );
              dispatch(setGuestInfo(guestId));

              window.location.href = '/';
            }}
          >
            <Flex gap={'16px'} align={'center'}>
              <LogoutIcon />
              <Text fontSize={'16px'} fontWeight={600} color="red">
                Logout
              </Text>
            </Flex>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Account;
